import AddIcon from 'Component/AddIcon';
import { FieldType } from 'Component/Field/Field.config';
import MinusIcon from 'Component/MinusIcon';
import { FieldNumberWithControlsComponent as SourceFieldNumberWithControlsComponent } from 'SourceComponent/FieldNumberWithControls/FieldNumberWithControls.component';
import { DEFAULT_MAX_PRODUCTS } from 'Util/Product/Product.type';

import './FieldNumberWithControls.override.style.scss';
/**
 * Field Number With Controls
 * @class FieldNumberWithControls
 * @namespace Pwa/Component/FieldNumberWithControls/Component */
export class FieldNumberWithControlsComponent extends SourceFieldNumberWithControlsComponent {
    render() {
        const {
            attr,
            attr: { min = 1, max = DEFAULT_MAX_PRODUCTS },
            value,
            events,
            setRef,
            stateValue,
            handleValueChange,
            isDisabled,
        } = this.props;

        const numberValue = +value || +stateValue;

        return (
            <>
        <input
          ref={ (elem) => setRef(elem) }
          { ...attr }
          { ...events }
          value={ value }
          type={ FieldType.NUMBER }
          // readOnly
          aria-label={ __('Value') }
          disabled={ isDisabled }
        />
        <button
          disabled={ max === 1 || numberValue >= max || isDisabled }
          onClick={ () => handleValueChange(numberValue + 1) }
          aria-label={ __('Add') }
          type={ FieldType.BUTTON }
        >
          <AddIcon block="SubtractButton" isPrimary />
        </button>
        <button
          disabled={ numberValue + 1 === min || numberValue <= min || isDisabled }
          onClick={ () => handleValueChange(numberValue - 1) }
          aria-label={ __('Subtract') }
          type={ FieldType.BUTTON }
        >
          <MinusIcon block="AddButton" isPrimary />
        </button>
            </>
        );
    }
}

export default FieldNumberWithControlsComponent;
