import parse from 'html-react-parser';

import ChevronIcon from 'Component/ChevronIcon';
import { Directions } from 'Component/ChevronIcon/ChevronIcon.config';
import ClickOutside from 'Component/ClickOutside';
import { FieldSelectComponent as SourceFieldSelect } from 'SourceComponent/FieldSelect/FieldSelect.component';
import { noopFn } from 'Util/Common';
/** @namespace Pwa/Component/FieldSelect/Component */
export class FieldSelectComponent extends SourceFieldSelect {
    renderOption = (option) => {
        const {
            id,
            label,
        } = option;

        const {
            isSelectExpanded: isExpanded,
            handleSelectListOptionClick, isDisabled,
        } = this.props;

        if (isDisabled) {
            return null;
        }

        return (
            <li
              block="FieldSelect"
              elem="Option"
              mods={ { isExpanded } }
              key={ id }
                /**
                 * Added 'o' as querySelector does not work with
                 * ids, that consist of numbers only
                 */
              id={ `o${id}` }
              role="menuitem"
              onClick={ () => handleSelectListOptionClick(option) }
              onKeyPress={ () => handleSelectListOptionClick(option) }
              tabIndex={ isExpanded ? '0' : '-1' }
            >
                { parse(label) }
            </li>
        );
    };

    renderNativeOption = (option) => {
        const {
            id,
            value,
            disabled,
            label,
        } = option;

        return (
            <option
              key={ id }
              id={ id }
              value={ value }
              disabled={ disabled }
              dangerouslySetInnerHTML={ { __html: label } }
            >
                { /* { label } */ }
            </option>
        );
    };

    renderNativeSelect() {
        const {
            setRef,
            attr,
            events,
            isDisabled,
            options,
            handleSelectListOptionClick,
            isSelectedOptionAvailable,
            value,
        } = this.props;

        return (
            <select
              block="FieldSelect"
              elem="Select"
              mods={ { isDisabled: !isSelectedOptionAvailable } }
              ref={ (elem) => setRef(elem) }
              disabled={ isDisabled }
              { ...attr }
              { ...events }
              value={ value }
              onChange={ handleSelectListOptionClick }
            >
                { options.map(this.renderNativeOption.bind(this)) }
            </select>
        );
    }

    render() {
        const {
            attr: { id = '' } = {},
            isExpanded,
            handleSelectExpand,
            handleSelectListKeyPress,
            handleSelectExpandedExpand,
            isDisabled,
        } = this.props;

        return (
            <ClickOutside onClick={ handleSelectExpandedExpand }>
                <div
                  id={ `${ id }_wrapper` }
                  block="FieldSelect"
                  mods={ { isExpanded } }
                  onClick={ !isDisabled ? handleSelectExpand : noopFn }
                  onKeyPress={ !isDisabled ? handleSelectListKeyPress : noopFn }
                  role="button"
                  tabIndex={ 0 }
                  aria-label="Select dropdown"
                  aria-expanded={ isExpanded }
                >
                    <div block="FieldSelect" elem="Clickable">
                        { this.renderSortSelect() }
                        { this.renderNativeSelect() }
                        <ChevronIcon direction={ isExpanded ? Directions.TOP : Directions.BOTTOM } />
                    </div>
                    { this.renderOptions() }
                </div>
            </ClickOutside>
        );
    }
}

export default FieldSelectComponent;
