import PropTypes from 'prop-types';
import { PureComponent } from 'react';

/** @namespace Pwa/Component/FieldInput/Component */
export class FieldInputComponent extends PureComponent {
    static propTypes = {
        formRef: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
        ]),
        validateSeparately: PropTypes.bool,
        isSubmitted: PropTypes.bool,
        fileExtensions: PropTypes.string,
    };

    static defaultProps = {
        formRef: () => {},
        validateSeparately: false,
        isSubmitted: false,
        fileExtensions: '',
    };

    render() {
        const {
            formRef,
            validateSeparately,
            isSubmitted,
            fileExtensions,
            ...validProps
        } = this.props;

        return <input ref={ formRef } { ...validProps } />;
    }
}

export default FieldInputComponent;
